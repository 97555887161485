import { CircularProgress, Container, Fab } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { callGetSermonSeries } from '../../client/api'
import { SermonSeries } from '../../client/types/sermon-series'
import CreateSermonSeriesForm from '../forms/CreateSermonSeriesForm'
import SermonSeriesItem from './SermonSeriesItem'
import DeleteDialog from '../common/DeleteDialog'
import EditModal from '../common/EditModal'

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: 16px;
  left: 16px;
`

const SermonSeriesPage: FC = () => {
  const navigate = useNavigate()
  const [isSermonSeriesLoading, setIsSermonSeriesLoading] =
    useState<boolean>(false)
  const [sermonSeries, setSermonSeries] = useState<SermonSeries[]>([])
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [sermonSeriesToEdit, setSermonSeriesToEdit] = useState<
    SermonSeries | undefined
  >()
  const [sermonSeriesToDelete, setSermonSeriesToDelete] = useState<
    SermonSeries | undefined
  >()

  useEffect(() => {
    const getSermonSeries = async () => {
      const response = await callGetSermonSeries()
      setSermonSeries(response.data.sermonSeries)
      setIsSermonSeriesLoading(false)
    }
    setIsSermonSeriesLoading(true)
    getSermonSeries()
  }, [])

  const handleEdit = (chosenSermonSeries: SermonSeries) => {
    setSermonSeriesToEdit(chosenSermonSeries)
    setOpenEditModal(true)
  }

  const handleClose = () => {
    setOpenEditModal(false)
  }

  const handleDelete = (chosenSermonSeries: SermonSeries) => {
    setSermonSeriesToDelete(chosenSermonSeries)
    setOpenDeleteDialog(true)
  }

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false)
    setSermonSeriesToDelete(undefined)
  }

  if (isSermonSeriesLoading) {
    return <CircularProgress />
  }
  return (
    <Container>
      {sermonSeries.map((series) => (
        <SermonSeriesItem
          sermonSeries={series}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ))}
      <StyledFab
        variant="extended"
        onClick={() => navigate('create')}
        color="primary"
      >
        Add Sermon Series
      </StyledFab>
      <EditModal openEditModal={openEditModal} handleClose={handleClose}>
        <CreateSermonSeriesForm defaultSermonSeries={sermonSeriesToEdit} />
      </EditModal>
      <DeleteDialog
        open={openDeleteDialog}
        sermonSeries={sermonSeriesToDelete}
        handleClose={handleCloseDelete}
      />
    </Container>
  )
}

export default SermonSeriesPage
