import { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { Edit, ExpandMore, Delete } from '@mui/icons-material'
import customTheme from '../../../theme'
import { Sermon } from '../../../client/types/sermon'
import { SermonSeries } from '../../../client/types/sermon-series'
import SermonItemActions from './SermonItemActions'

const StyledAccordionSummary = styled(AccordionSummary)`
  margin-bottom: 30px;
  padding: 21px 29px;
  background-color: ${customTheme.palette.neutral.dark};
  border-radius: 5px;
`

const SermonUpdateActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

const SermonInfo = styled.div`
  flex-grow: 1;

  min-width: 0;
  padding: 0 10% 0 0;
  flex-direction: column;

  ${customTheme.breakpoints.down('sm')} {
    padding: 0 5% 0 0;
  }
`

const SermonDate = styled(Typography)`
  margin-bottom: 10px;
  ${customTheme.breakpoints.down('sm')} {
    font-size: 0.85rem;
  }
`

const SermonDetails = styled.div`
  display: flex;
  font-size: 1.15rem;

  > :not(:last-child) {
    margin-right: 30px;
  }

  ${customTheme.breakpoints.down('md')} {
    flex-direction: column;

    font-size: 0.85rem;
  }
`

const SermonDetailGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const SermonTitle = styled(Typography)`
  font-weight: 700;
  margin-bottom: 18px;

  ${customTheme.breakpoints.down('sm')} {
    font-size: 1.3rem;
  }
`

type SermonItemProps = {
  sermon: Sermon
  sermonSeries: SermonSeries[]
  handleEdit: (sermon: Sermon) => void
  handleDelete: (sermon: Sermon) => void
  selectedSermon?: Sermon | undefined
  handleOnClick: (sermon?: Sermon) => void
}

const SermonItem: FC<SermonItemProps> = ({
  sermon,
  sermonSeries,
  handleEdit,
  handleDelete,
  selectedSermon,
  handleOnClick,
}) => {
  const [expand, setExpand] = useState<boolean>(false)

  return (
    <Accordion expanded={expand}>
      <StyledAccordionSummary
        expandIcon={<ExpandMore onClick={() => setExpand(!expand)} />}
      >
        <SermonUpdateActions>
          <Button onClick={() => handleEdit(sermon)}>
            <Edit />
          </Button>
          <Button onClick={() => handleDelete(sermon)}>
            <Delete />
          </Button>
        </SermonUpdateActions>
        <SermonInfo>
          <SermonDate variant="body1">
            {format(parseISO(sermon.date), 'PPP')}
          </SermonDate>
          <SermonTitle variant="h3">{sermon.title}</SermonTitle>
          <SermonDetails>
            <SermonDetailGroup>
              <Typography variant="body1" color="secondary.light">
                Scripture
              </Typography>
              <Typography variant="body2" color="primary.dark">
                {sermon.verse}
              </Typography>
            </SermonDetailGroup>
            <SermonDetailGroup>
              <Typography variant="body1" color="secondary.light">
                Series
              </Typography>
              <Typography variant="body2" color="primary.dark">
                {
                  sermonSeries.find((series) => series.tagId === sermon.tagId)
                    ?.title
                }
              </Typography>
            </SermonDetailGroup>
            <SermonDetailGroup>
              <Typography variant="body1" color="secondary.light">
                Preacher
              </Typography>
              <Typography variant="body2" color="primary.dark">
                {sermon.speakerName}
              </Typography>
            </SermonDetailGroup>
          </SermonDetails>
        </SermonInfo>
        <SermonItemActions
          sermon={sermon}
          selectedSermon={selectedSermon}
          handleOnClick={handleOnClick}
        />
      </StyledAccordionSummary>
      <AccordionDetails>
        <SermonDetailGroup>
          <Typography variant="body1" color="secondary.light">
            Video Location
          </Typography>
          <Typography variant="body2" color="primary.dark">
            {sermon.videoLocation || 'None'}
          </Typography>
        </SermonDetailGroup>
        <SermonDetailGroup>
          <Typography variant="body1" color="secondary.light">
            Audio Location
          </Typography>
          <Typography variant="body2" color="primary.dark">
            {sermon.audioLocation || 'None'}
          </Typography>
        </SermonDetailGroup>
        <SermonDetailGroup>
          <Typography variant="body1" color="secondary.light">
            Image Location
          </Typography>
          <Typography variant="body2" color="primary.dark">
            {sermon.imageLocation || 'None'}
          </Typography>
        </SermonDetailGroup>
      </AccordionDetails>
    </Accordion>
  )
}

export default SermonItem
