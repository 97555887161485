import axios, { AxiosResponse } from 'axios'
import AWS from 'aws-sdk'
import { CreateSermonRequest, GetSermonListResponse } from './types/sermon'
import {
  CreateSermonSeriesRequest,
  GetSermonSeriesListResponse,
} from './types/sermon-series'
import { getCurrentUser } from '../common/auth'
// import { DUMMY_SERMONS, DUMMY_SERMON_SERIES } from './dummyData'

const S3_BUCKET = 'crosslife-sermons-2.0'
const REGION = 'us-west-2'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

const APIClient = axios.create({
  baseURL: 'https://api.crosslifebiblechurch.org',
  timeout: 5000,
})

const APICreateClient = axios.create({
  baseURL: 'https://q51f7wphn0.execute-api.us-west-2.amazonaws.com/private',
  timeout: 5000,
  // headers: {
  //   'x-api-key': 'iySa9lLLLK98Zv81JBTcc6RWpPKbMjy82X2Q19YV',
  // },
})

export const callGetSermonSeries = async (): Promise<
  AxiosResponse<GetSermonSeriesListResponse>
> => {
  return APIClient.get('/sermon-series')
}

export type GetSermonListRequest = {
  tagId?: string
  match?: string
  nextToken?: string
}

export const callGetSermons = async ({
  match,
  nextToken,
}: GetSermonListRequest): Promise<AxiosResponse<GetSermonListResponse>> => {
  return APIClient.get('/sermons', {
    params: {
      match: match?.toLowerCase(),
      nextToken,
    },
  })
}

const uploadAudioFile = async (file: File) => {
  const params = {
    Bucket: S3_BUCKET,
    Key: file.name,
    Body: file,
  }
  const upload = s3.putObject(params).promise()

  const getSignedUrlParams = {
    Bucket: S3_BUCKET,
    Key: file.name,
  }

  const audioLocation = await upload
    .then(() => {
      return s3.getSignedUrl('getObject', getSignedUrlParams)
    })
    .catch((ex) => {
      throw ex
    })

  return audioLocation
}

export const callCreateSermon = async (
  createSermonRequest: CreateSermonRequest,
  file?: File
) => {
  const user: any = await getCurrentUser()
  const authToken = await user.getIdToken()

  if (file) {
    createSermonRequest.audioLocation = await uploadAudioFile(file)
  }
  await APICreateClient.post('/sermons', createSermonRequest, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
}

export const callCreateSermonSeries = async (
  createSermonSeriesRequest: CreateSermonSeriesRequest
) => {
  const user: any = await getCurrentUser()
  const authToken = await user.getIdToken()

  await APICreateClient.post('/sermon-series', createSermonSeriesRequest, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
}

export const callUpdateSermon = async (
  updateSermonRequest: CreateSermonRequest,
  file?: File
) => {
  const user: any = await getCurrentUser()
  const authToken = await user.getIdToken()

  if (file) {
    updateSermonRequest.audioLocation = await uploadAudioFile(file)
  }
  await APICreateClient.put('/sermons', updateSermonRequest, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
}

export const callUpdateSermonSeries = async (
  updateSermonSeriesRequest: CreateSermonSeriesRequest
) => {
  const user: any = await getCurrentUser()
  const authToken = await user.getIdToken()

  await APICreateClient.put('/sermon-series', updateSermonSeriesRequest, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
}

export const callDeleteSermon = async (sermonId?: string) => {
  const user: any = await getCurrentUser()
  const authToken = await user.getIdToken()

  await APICreateClient.delete(`/sermons/${sermonId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
}
