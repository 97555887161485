import { Drawer, IconButton, Modal, Paper, useMediaQuery } from '@mui/material'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Close } from '@mui/icons-material'
import customTheme from '../../theme'

type EditModalProps = {
  openEditModal: boolean
  handleClose: () => void
  children: ReactNode
}

const StyledPaper = styled(Paper)`
  margin-top: 25vh;
  margin-left: 15vh;
  margin-right: 15vh;
  padding: 30px;
`

const DrawerHeader = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'flex-end';
`

const EditModal: FC<EditModalProps> = ({
  openEditModal,
  handleClose,
  children,
}) => {
  const matches = useMediaQuery(customTheme.breakpoints.down('sm'))

  if (matches) {
    return (
      <Drawer anchor="bottom" open={openEditModal} onClose={handleClose}>
        <DrawerHeader>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DrawerHeader>
        <Paper>{children}</Paper>
      </Drawer>
    )
  }

  return (
    <Modal open={openEditModal} onClose={handleClose}>
      <StyledPaper>{children}</StyledPaper>
    </Modal>
  )
}

export default EditModal
