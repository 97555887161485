import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'

type SermonSearchProps = {
  /**
   * Function to run when a sermon series selection changes.
   */
  handleFormSubmit: (titleSearch?: string) => void

  className?: string
}

/**
 * Depending on the type of media, there can be multiple actions done.
 */
const SermonSearch: FC<SermonSearchProps> = ({
  handleFormSubmit,
  className,
}) => {
  const [searchText, setSearchText] = useState('')

  const handleClear = () => {
    setSearchText('')
    handleFormSubmit('')
  }

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      handleClear()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  })

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        handleFormSubmit(searchText)
      }}
    >
      <TextField
        value={searchText}
        className={className}
        fullWidth
        label="Search"
        color="primary"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClear}
                sx={{ visibility: searchText ? 'visible' : 'hidden' }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          setSearchText(event.target.value)
        }}
      />
    </form>
  )
}

export default SermonSearch
