import { Logout, Menu, Person } from '@mui/icons-material'
import {
  List,
  ListItem,
  Button,
  Collapse,
  ListItemText,
  ListItemButton,
} from '@mui/material'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import customTheme from '../../../theme'
import { logout } from '../../../common/auth'

const Brand = styled.img`
  width: 225px;

  ${customTheme.breakpoints.down('sm')} {
    width: 145px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;

  &:visited,
  &:active {
    color: black;
  }
`

const MobileNavBar: FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const handleLogOut = () => {
    logout()
    setOpen(!open)
  }

  return (
    <List>
      <ListItem>
        <Button onClick={handleClick}>
          <Menu />
        </Button>
        <Brand
          src="https://assets.crosslifebiblechurch.org/crosslife-logo.svg"
          alt="crosslife-logo"
          onClick={() => {
            setOpen(false)
          }}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          style={{
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          disablePadding
        >
          <div>
            <ListItemButton onClick={() => setOpen(false)}>
              <StyledLink to="/">
                <ListItemText primary="Sermons" />
              </StyledLink>
            </ListItemButton>
            <ListItemButton onClick={() => setOpen(false)}>
              <StyledLink to="/sermon-series">
                <ListItemText primary="Sermon Series" />
              </StyledLink>
            </ListItemButton>
          </div>
          <div>
            <ListItemButton>
              <Person />
              <ListItemText primary="Profile" />
            </ListItemButton>
            <ListItemButton onClick={handleLogOut}>
              <StyledLink to="/login">
                <Logout />
                <ListItemText primary="Log Out" />
              </StyledLink>
            </ListItemButton>
          </div>
        </List>
      </Collapse>
    </List>
  )
}

export default MobileNavBar
