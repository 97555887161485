import { Button } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import TvIcon from '@mui/icons-material/Tv'
import { Sermon } from '../../../client/types/sermon'
import customTheme from '../../../theme'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    min-width: 56px;
    height: 49px;

    padding: 0;
    margin: 0 15px 0 0;
    border-width: 2px;
  }

  button {
    min-width: 56px;
    height: 49px;

    padding: 0;
    margin: 0 15px 0 0;
  }

  ${customTheme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: center;

    a {
      font-size: 0.75rem;
      margin: 0 0 15px 0;
    }

    button {
      font-size: 0.75rem;
      margin: 0 0 15px 0;
    }
  }
`

type SermonActionsProps = {
  sermon: Sermon
  selectedSermon?: Sermon
  handleOnClick: (sermon?: Sermon) => void
}

/**
 * Depending on the type of media, there can be multiple actions done.
 */
const SermonActions: FC<SermonActionsProps> = ({
  sermon,
  selectedSermon,
  handleOnClick,
}) => {
  const onClick = () => {
    if (selectedSermon?.id === sermon?.id) {
      handleOnClick(undefined)
    } else {
      handleOnClick(sermon)
    }
  }

  return (
    <Container>
      {sermon.audioLocation && (
        <>
          <Button
            variant="contained"
            component="button"
            color={selectedSermon?.id === sermon.id ? 'warning' : 'primary'}
            onClick={onClick}
          >
            {selectedSermon?.id === sermon.id ? (
              <StopCircleIcon />
            ) : (
              <PlayArrowIcon />
            )}
          </Button>
          <Button
            variant="outlined"
            component="a"
            href={sermon.audioLocation}
            target="_blank"
            color="primary"
          >
            <FileDownloadOutlinedIcon />
          </Button>
        </>
      )}
      {sermon.videoLocation && (
        <Button
          variant="contained"
          component="a"
          href={sermon.videoLocation}
          target="_blank"
          color="primary"
        >
          <TvIcon />
        </Button>
      )}
    </Container>
  )
}

export default SermonActions
