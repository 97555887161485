import { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import NavTabs from './NavTabs/NavTabs'
import ProfileNav from './ProfileNav/ProfileNav'

const NavBarContainer = styled(Stack)`
  height: 100px;
  padding: 0 1% 0 0;
`

const Brand = styled.img`
  width: 225px;
`

const DesktopNavBar: FC = () => {
  return (
    <NavBarContainer
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="0 1%"
      height="100px"
    >
      <Link to="/">
        <Brand
          src="https://assets.crosslifebiblechurch.org/crosslife-logo.svg"
          alt="crosslife-logo"
        />
      </Link>
      <NavTabs />
      <ProfileNav />
    </NavBarContainer>
  )
}
export default DesktopNavBar
