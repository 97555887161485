import { FC, useState } from 'react'
import {
  FormLabel,
  Button,
  TextField,
  Container,
  Alert,
  Snackbar,
  CircularProgress,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { SermonSeries } from '../../client/types/sermon-series'
import {
  callCreateSermonSeries,
  callUpdateSermonSeries,
} from '../../client/api'
import customTheme from '../../theme'

const StyledFormDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${customTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

type CreateSermonSeriesFormProps = {
  defaultSermonSeries?: SermonSeries
}

yup.addMethod(yup.object, 'dayjs', function method(message) {
  return this.test('dayjs', message, function validate(value) {
    if (!value) {
      return true
    }
    return dayjs.isDayjs(value)
  })
})

yup.addMethod(yup.object, 'endDateAfterStartDate', function method(message) {
  return this.test('dayjs', message, function validate(value, context) {
    return value === null || context.parent.startDate.isBefore(value)
  })
})

const schema = yup.object({
  tagId: yup.string().required('Please enter a tag ID'),
  title: yup.string().required('Please enter a title'),
  description: yup.string().optional(),
  startDate: yup.object().dayjs().required('Please select a date'),
  endDate: yup
    .object()
    .dayjs()
    .endDateAfterStartDate(`Please select an end date after the start date`)
    .nullable()
    .optional(),
  imageLocation: yup.string().url('Please enter a valid url').optional(),
  initialCount: yup.number().optional(),
})

const CreateSermonSeriesForm: FC<CreateSermonSeriesFormProps> = ({
  defaultSermonSeries,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tagId: defaultSermonSeries?.tagId || '',
      title: defaultSermonSeries?.title || '',
      description: defaultSermonSeries?.description || '',
      startDate: dayjs(defaultSermonSeries?.startDate) || dayjs(new Date()),
      endDate: defaultSermonSeries?.endDate
        ? dayjs(defaultSermonSeries?.endDate)
        : null,
      initialCount: defaultSermonSeries?.sermonCount || 0,
      imageLocation: defaultSermonSeries?.imageLocation || '',
    },
    resolver: yupResolver(schema),
  })
  const navigate = useNavigate()
  const [showCreateSermonSeriesSuccess, setShowCreateSermonSeriesSuccess] =
    useState<boolean>(false)
  const [showCreateSermonSeriesError, setShowCreateSermonSeriesError] =
    useState<boolean>(false)
  const [createSermonSeriesError, setCreateSermonSeriesError] =
    useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const submitForm = async (formData: any) => {
    // do something with the file (upload to s3)
    // update backend, if there is file use that as url for audio location instead
    try {
      setIsSubmitting(true)
      if (defaultSermonSeries) {
        const updateData = formData
        updateData.id = defaultSermonSeries.id
        await callUpdateSermonSeries(updateData)
      } else {
        await callCreateSermonSeries(formData)
      }
      if (defaultSermonSeries) navigate(0)
      else navigate('/sermon-series')
      setShowCreateSermonSeriesSuccess(true)
    } catch (err: any) {
      setCreateSermonSeriesError(err.message)
      setShowCreateSermonSeriesError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCloseCreateSermonSeriesError = () => {
    setCreateSermonSeriesError('')
    setShowCreateSermonSeriesError(false)
  }

  const handleCloseCreateSermonSeriesSuccess = () => {
    setShowCreateSermonSeriesSuccess(false)
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(submitForm)}>
        <StyledFormDiv>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!defaultSermonSeries && (
              <>
                <FormLabel>Tag ID</FormLabel>
                <TextField
                  {...register('tagId')}
                  error={!!errors?.tagId}
                  helperText={errors?.tagId?.message?.toString()}
                />
              </>
            )}
            <FormLabel>Title</FormLabel>
            <TextField
              {...register('title')}
              error={!!errors?.title}
              helperText={errors?.title?.message?.toString()}
            />
            <FormLabel>Description</FormLabel>
            <TextField
              {...register('description')}
              error={!!errors?.description}
              helperText={errors?.description?.message?.toString()}
            />
            <FormLabel>Sermon Count</FormLabel>
            <TextField
              type="number"
              {...register('initialCount')}
              error={!!errors?.initialCount}
              helperText={errors?.initialCount?.message?.toString()}
            />
            <FormLabel>Start Date</FormLabel>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => {
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors?.startDate,
                          helperText: errors?.startDate?.message?.toString(),
                        },
                      }}
                    />
                  </LocalizationProvider>
                )
              }}
            />
            <FormLabel>End Date</FormLabel>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => {
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors?.endDate,
                          helperText: errors?.endDate?.message?.toString(),
                        },
                      }}
                    />
                  </LocalizationProvider>
                )
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormLabel>Image Location</FormLabel>
            <TextField
              {...register('imageLocation')}
              error={!!errors?.imageLocation}
              helperText={errors?.imageLocation?.message?.toString()}
            />
            {!isSubmitting ? (
              <Button type="submit">
                {defaultSermonSeries ? 'Update' : 'Submit'}
              </Button>
            ) : (
              <CircularProgress />
            )}
          </div>
        </StyledFormDiv>
      </form>
      <Snackbar
        open={showCreateSermonSeriesError}
        onClose={handleCloseCreateSermonSeriesError}
        autoHideDuration={3000}
      >
        <Alert severity="error">{createSermonSeriesError}</Alert>
      </Snackbar>
      <Snackbar
        open={showCreateSermonSeriesSuccess}
        onClose={handleCloseCreateSermonSeriesSuccess}
        autoHideDuration={3000}
      >
        <Alert severity="success">Created sermon series successfully!</Alert>
      </Snackbar>
    </Container>
  )
}

export default CreateSermonSeriesForm
