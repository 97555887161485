import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Typography,
  Container,
  TextField,
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginWithEmailPassword, loginWithGoogle } from '../../common/auth'

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Brand = styled.img`
  width: 225px;
`

const schema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  password: yup.string().required('Please enter your password'),
})

const Login: FC = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const [showLoginError, setShowLoginError] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const login = async (loginFormData: any) => {
    const { email, password } = loginFormData
    try {
      setIsSubmitting(true)
      await loginWithEmailPassword(email, password)
      navigate('/')
    } catch (err: any) {
      setLoginError(err.message)
      setShowLoginError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCloseLoginError = () => {
    setLoginError('')
    setShowLoginError(false)
  }

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit(login)}>
        <Stack spacing={2}>
          <Brand
            src="https://assets.crosslifebiblechurch.org/crosslife-logo.svg"
            alt="crosslife-logo"
          />
          <Typography variant="h6" component="h6">
            Admin Portal
          </Typography>
          <TextField
            label="email"
            error={!!errors?.email}
            helperText={errors?.email?.message?.toString()}
            {...register('email')}
          />
          <TextField
            label="password"
            type="password"
            {...register('password')}
            error={!!errors?.password}
            helperText={errors?.password?.message?.toString()}
          />
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="contained" type="submit">
                Log In
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={() => loginWithGoogle('/', navigate)}
              >
                Log In with Google
              </Button>
            </>
          )}
          <Snackbar
            open={showLoginError}
            onClose={handleCloseLoginError}
            autoHideDuration={3000}
          >
            <Alert severity="error">{loginError}</Alert>
          </Snackbar>
        </Stack>
      </form>
    </StyledContainer>
  )
}

export default Login
