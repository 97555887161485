import { Button, Paper, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { FC } from 'react'
import styled from 'styled-components'
import { Delete, Edit } from '@mui/icons-material'
import { SermonSeries } from '../../client/types/sermon-series'

const StyledPaper = styled(Paper)`
  display: flex;

  margin-bottom: 30px;
  padding: 21px 29px;

  border-radius: 5px;
`

const SermonUpdateActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

type SermonSeriesProps = {
  sermonSeries: SermonSeries
  handleEdit: (chosenSermonSeries: SermonSeries) => void
  handleDelete: (chosenSermonSeries: SermonSeries) => void
}

const SermonSeriesItem: FC<SermonSeriesProps> = ({
  sermonSeries,
  handleEdit,
  handleDelete,
}) => {
  return (
    <StyledPaper key={sermonSeries.id} elevation={0} variant="secondary">
      <SermonUpdateActions>
        <Button onClick={() => handleEdit(sermonSeries)}>
          <Edit />
        </Button>
        <Button onClick={() => handleDelete(sermonSeries)}>
          <Delete />
        </Button>
      </SermonUpdateActions>
      <div>
        <Typography variant="h3">{sermonSeries.title}</Typography>
        <div>
          <Typography>
            {format(parseISO(sermonSeries.startDate), 'PPP')} to{' '}
            {sermonSeries.endDate
              ? format(parseISO(sermonSeries.endDate), 'PPP')
              : 'Present'}
          </Typography>
          <Typography>{sermonSeries.sermonCount} sermons</Typography>
        </div>
      </div>
    </StyledPaper>
  )
}

export default SermonSeriesItem
