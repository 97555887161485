import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControl,
  FormLabel,
  Button,
  TextField,
  MenuItem,
  Select,
  Container,
  CircularProgress,
  Typography,
  FormHelperText,
  Alert,
  Snackbar,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import FileUpload from '../common/Form/FileUpload'
import { Sermon } from '../../client/types/sermon'
import { SermonSeries } from '../../client/types/sermon-series'
import {
  callCreateSermon,
  callGetSermonSeries,
  callUpdateSermon,
} from '../../client/api'
import customTheme from '../../theme'

const StyledFormDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${customTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

type CreateSermonFormProps = {
  defaultSermon?: Sermon
}

yup.addMethod(yup.object, 'dayjs', function method(message) {
  return this.test('dayjs', message, function validate(value) {
    if (!value) {
      return true
    }
    return dayjs.isDayjs(value)
  })
})

const schema = yup.object({
  tagId: yup.string().required('Please choose a sermon series'),
  title: yup.string().required('Please enter a title'),
  verse: yup.string().optional(),
  date: yup.object().dayjs().required('Please select a date'),
  speakerName: yup.string().required('Please enter a speaker name'),
  imageLocation: yup.string().url('Please enter a valid url').optional(),
  audioLocation: yup.string().url('Please enter a valid url').optional(),
  videoLocation: yup.string().url('Please enter a valid url').optional(),
})

const CreateSermonForm: FC<CreateSermonFormProps> = ({ defaultSermon }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tagId: defaultSermon?.tagId || '',
      title: defaultSermon?.title || '',
      verse: defaultSermon?.verse || '',
      date: dayjs(defaultSermon?.date) || dayjs(new Date()),
      speakerName: defaultSermon?.speakerName || '',
      imageLocation: defaultSermon?.imageLocation || '',
      audioLocation: defaultSermon?.audioLocation || '',
      videoLocation: defaultSermon?.videoLocation || '',
    },
    resolver: yupResolver(schema),
  })
  const navigate = useNavigate()
  const [isSermonSeriesLoading, setIsSermonSeriesLoading] =
    useState<boolean>(false)
  const [sermonSeries, setSermonSeries] = useState<SermonSeries[]>([])

  const [file, setFile] = useState<File[]>([])
  const [showCreateSermonError, setShowCreateSermonError] =
    useState<boolean>(false)
  const [showCreateSermonSuccess, setShowCreateSermonSuccess] =
    useState<boolean>(false)
  const [createSermonError, setCreateSermonError] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    const getSermonSeries = async () => {
      const response = await callGetSermonSeries()
      setSermonSeries(response.data.sermonSeries)
      setIsSermonSeriesLoading(false)
    }
    setIsSermonSeriesLoading(true)
    getSermonSeries()
  }, [])

  const submitForm = async (formData: any) => {
    // do something with the file (upload to s3)
    // update backend, if there is file use that as url for audio location instead
    try {
      setIsSubmitting(true)
      if (defaultSermon) {
        const updateData = formData
        updateData.id = defaultSermon.id
        await callUpdateSermon(updateData, file[0])
      } else {
        await callCreateSermon(formData, file[0])
      }
      setShowCreateSermonSuccess(true)
      if (defaultSermon) navigate(0)
      else navigate('/')
    } catch (err: any) {
      setCreateSermonError(err.message)
      setShowCreateSermonError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCloseCreateSermonError = () => {
    setCreateSermonError('')
    setShowCreateSermonError(false)
  }

  const handleCloseCreateSermonSuccess = () => {
    setShowCreateSermonSuccess(false)
  }

  if (isSermonSeriesLoading) {
    return <CircularProgress />
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(submitForm)}>
        <StyledFormDiv>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl>
              <FormLabel>Sermon Series</FormLabel>
              <Controller
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <Select
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      error={!!errors?.tagId}
                    >
                      {sermonSeries.map((series) => (
                        <MenuItem key={series.id} value={series.tagId}>
                          {series.title}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {errors?.tagId?.message?.toString()}
                    </FormHelperText>
                  </>
                )}
                name="tagId"
                control={control}
              />
            </FormControl>
            <FormLabel>Title</FormLabel>
            <TextField
              {...register('title')}
              error={!!errors?.title}
              helperText={errors?.title?.message?.toString()}
            />
            <FormLabel>Verse</FormLabel>
            <TextField
              {...register('verse')}
              error={!!errors?.verse}
              helperText={errors?.verse?.message?.toString()}
            />
            <FormLabel>Date</FormLabel>
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              defaultValue={dayjs(new Date())}
              render={({ field }) => {
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                    />
                  </LocalizationProvider>
                )
              }}
            />
            <FormLabel>Speaker Name</FormLabel>
            <TextField
              {...register('speakerName')}
              error={!!errors?.speakerName}
              helperText={errors?.speakerName?.message?.toString()}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormLabel>Image Location</FormLabel>
            <TextField
              {...register('imageLocation')}
              error={!!errors?.imageLocation}
              helperText={errors?.imageLocation?.message?.toString()}
            />
            <FormLabel>Video Location</FormLabel>
            <TextField
              {...register('videoLocation')}
              error={!!errors?.videoLocation}
              helperText={errors?.videoLocation?.message?.toString()}
            />
            <FormLabel>Audio Location</FormLabel>
            <TextField
              {...register('audioLocation')}
              error={!!errors?.audioLocation}
              helperText={errors?.audioLocation?.message?.toString()}
            />
            <Typography>-OR-</Typography>
            <FileUpload setFiles={setFile} />
            {!isSubmitting ? (
              <Button type="submit">
                {defaultSermon ? 'Update' : 'Submit'}
              </Button>
            ) : (
              <CircularProgress />
            )}
          </div>
        </StyledFormDiv>
      </form>
      <Snackbar
        open={showCreateSermonError}
        onClose={handleCloseCreateSermonError}
        autoHideDuration={3000}
      >
        <Alert severity="error">{createSermonError}</Alert>
      </Snackbar>
      <Snackbar
        open={showCreateSermonSuccess}
        onClose={handleCloseCreateSermonSuccess}
        autoHideDuration={3000}
      >
        <Alert severity="success">Created sermon successfully!</Alert>
      </Snackbar>
    </Container>
  )
}

export default CreateSermonForm
