import { FC, useEffect, useState } from 'react'
import { CircularProgress, Container, Fab } from '@mui/material'
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import { Sermon } from '../../client/types/sermon'
import CreateSermonForm from '../forms/CreateSermonForm'
import { callGetSermonSeries } from '../../client/api'
import { SermonSeries } from '../../client/types/sermon-series'
import SermonSearch from './search/SermonSearch'
import DeleteDialog from '../common/DeleteDialog'
import EditModal from '../common/EditModal'

// const StyledPaper = styled(Paper)`
//   margin-top: 25vh;
//   margin-left: 15vh;
//   margin-right: 15vh;
//   padding: 30px;
// `

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: 16px;
  left: 16px;
`

const Home: FC = () => {
  const navigate = useNavigate()
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [sermonSeries, setSermonSeries] = useState<SermonSeries[]>([])
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [sermonToEdit, setSermonToEdit] = useState<Sermon | undefined>()
  const [sermonToDelete, setSermonToDelete] = useState<Sermon | undefined>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const getData = async () => {
      const sermonSeriesResponse = await callGetSermonSeries()
      setSermonSeries(sermonSeriesResponse.data.sermonSeries)
      setIsLoadingData(false)
    }
    setIsLoadingData(true)
    getData()
  }, [])

  const handleEdit = (sermon: Sermon) => {
    setSermonToEdit(sermon)
    setOpenEditModal(true)
  }

  const handleDelete = (sermon: Sermon) => {
    setSermonToDelete(sermon)
    setOpenDeleteDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleClose = () => {
    setOpenEditModal(false)
    setSermonToEdit(undefined)
  }

  const handleSearchSubmit = (titleSearch?: string) => {
    setSearchParams(titleSearch ? { match: titleSearch } : {})
  }

  if (isLoadingData) {
    return <CircularProgress />
  }

  return (
    <Container>
      <SermonSearch handleFormSubmit={handleSearchSubmit} />
      <Dashboard
        sermonSeries={sermonSeries}
        searchParams={searchParams}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <EditModal openEditModal={openEditModal} handleClose={handleClose}>
        <CreateSermonForm defaultSermon={sermonToEdit} />
      </EditModal>
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={handleCloseDialog}
        sermon={sermonToDelete}
      />
      <StyledFab
        variant="extended"
        color="primary"
        onClick={() => navigate('/create')}
      >
        Add Sermon
      </StyledFab>
    </Container>
  )
}

export default Home
