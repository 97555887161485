import { Stack } from '@mui/material'
import { FC } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import styled from 'styled-components'
import NavBar from './components/nav/NavBar'

const FlexibleContainer = styled.div`
  flex-grow: 1;
  padding: 30px;
`

const App: FC = () => {
  return (
    <Stack direction="column" sx={{ height: '100vh' }}>
      <NavBar />
      <FlexibleContainer>
        <Outlet />
      </FlexibleContainer>
      <ScrollRestoration />
    </Stack>
  )
}

export default App
