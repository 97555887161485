import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import FirebaseApp from '../service/firebase'

const loginWithEmailPassword = async (email: string, password: string) => {
  const auth = getAuth(FirebaseApp)
  await setPersistence(auth, browserSessionPersistence)
  await signInWithEmailAndPassword(auth, email, password)
}

const loginWithGoogle = async (
  redirectPath: string,
  navigate: NavigateFunction
) => {
  const auth = getAuth(FirebaseApp)
  const provider = new GoogleAuthProvider()

  try {
    await setPersistence(auth, browserSessionPersistence)
    try {
      const result = await signInWithPopup(auth, provider)
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const token = credential?.accessToken
      const { user } = result
      // eslint-disable-next-line no-console
      console.log('successful', token, user)
      navigate(redirectPath)
    } catch (error) {
      // console.log(error)
    }
  } catch (error) {
    // console.log(error)
  }
}

const logout = () => {
  return getAuth(FirebaseApp).signOut()
}

const getCurrentUser = () =>
  new Promise((resolve, reject) => {
    const unsubscribe = getAuth(FirebaseApp).onAuthStateChanged(
      (user) => {
        unsubscribe()
        resolve(user)
      },
      (err) => {
        reject(err)
      }
    )
  })

export { loginWithEmailPassword, loginWithGoogle, logout, getCurrentUser }
