import { FC, useCallback, useEffect, useState } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Sermon } from '../../../client/types/sermon'
import { SermonSeries } from '../../../client/types/sermon-series'
import { callGetSermons } from '../../../client/api'
import SermonItem from './SermonItem'
import SermonAudioPlayer from './SermonAudioPlayer'

type DashboardProps = {
  sermonSeries: SermonSeries[]
  searchParams?: URLSearchParams
  handleEdit: (sermon: Sermon) => void
  handleDelete: (sermon: Sermon) => void
}

const Dashboard: FC<DashboardProps> = ({
  sermonSeries,
  searchParams,
  handleEdit,
  handleDelete,
}) => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [sermons, setSermons] = useState<Sermon[]>([])
  const [nextPageToken, setNextPageToken] = useState<string | undefined>()

  const [selectedSermonToListenTo, setSelectedSermonToListenTo] = useState<
    Sermon | undefined
  >()

  const getSermonListNextPage = useCallback(
    async (params?: URLSearchParams) => {
      const response = await callGetSermons({
        nextToken: nextPageToken,
        match: params?.get('match') || undefined,
      })
      setSermons([...sermons, ...response.data.sermons])
      setNextPageToken(response.data.nextToken)
    },
    [nextPageToken]
  )

  useEffect(() => {
    const getData = async (params?: URLSearchParams) => {
      const sermonResponse = await callGetSermons({
        match: params?.get('match') || undefined,
      })
      setSermons(sermonResponse.data.sermons)
      setNextPageToken(sermonResponse.data.nextToken)
      setIsLoadingData(false)
    }
    setIsLoadingData(true)
    getData(searchParams)
  }, [searchParams])

  if (isLoadingData) {
    return <CircularProgress />
  }

  return (
    <div>
      {searchParams?.get('match') && (
        <Typography>
          Results for &quot;{searchParams?.get('match')}&quot;
        </Typography>
      )}
      <InfiniteScroll
        dataLength={sermons.length}
        next={() => getSermonListNextPage(searchParams)}
        loader={<h4>Loading...</h4>}
        hasMore={nextPageToken !== undefined}
        style={{ width: '100%' }}
      >
        {sermons.map((sermon) => (
          <SermonItem
            sermon={sermon}
            sermonSeries={sermonSeries}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            selectedSermon={selectedSermonToListenTo}
            handleOnClick={setSelectedSermonToListenTo}
          />
        ))}
      </InfiniteScroll>
      <SermonAudioPlayer selectedSermon={selectedSermonToListenTo} />
    </div>
  )
}

export default Dashboard
