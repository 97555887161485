import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { createRoot } from 'react-dom/client'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from 'react-router-dom'
import App from './App'
import customTheme from './theme'
import CreateSermonForm from './components/forms/CreateSermonForm'
import CreateSermonSeriesForm from './components/forms/CreateSermonSeriesForm'
import Login from './components/login/Login'
import Home from './components/home/Home'
import SermonSeriesPage from './components/sermon-series/SermonSeriesPage'
import { getCurrentUser } from './common/auth'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Karla', 'Avenir', 'Roboto', sans-serif;
    box-sizing: border-box;
  }

  div {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
}`

const isAuth = async () => {
  try {
    const user = await getCurrentUser()
    if (!user) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw redirect('/login')
    }
  } catch (ex) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect('/login')
  }

  return null
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<App />} loader={isAuth}>
        <Route index element={<Home />} />
        <Route path="create" element={<CreateSermonForm />} />
        <Route path="sermon-series" element={<SermonSeriesPage />} />
        <Route
          path="sermon-series/create"
          element={<CreateSermonSeriesForm />}
        />
      </Route>
      <Route path="login" element={<Login />} />
    </Route>
  )
)

const domNode = document.getElementById('root')!

const root = createRoot(domNode)

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={customTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)
