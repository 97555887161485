import { initializeApp } from 'firebase/app'

// const firebaseConfig = {
//   apiKey: 'AIzaSyDH1PB-izPF3JCsPDYXU7oMxElpsdOys8s',
//   authDomain: 'skilful-rig-394504.firebaseapp.com',
//   projectId: 'skilful-rig-394504',
//   storageBucket: 'skilful-rig-394504.appspot.com',
//   messagingSenderId: '709408903411',
//   appId: '1:709408903411:web:5d495cf815357ab20be786',
//   measurementId: 'G-RMCCZQVZCH',
// }

const firebaseConfig = {
  apiKey: 'AIzaSyB-icmS-rEq4MxPU-TO3Zg9lw7qmQTLL8w',
  authDomain: 'croifeadminportal.firebaseapp.com',
  projectId: 'croifeadminportal',
  storageBucket: 'croifeadminportal.appspot.com',
  messagingSenderId: '20500834014',
  appId: '1:20500834014:web:54a85d464d0a610e09844f',
  measurementId: 'G-2DDMJK1RPP',
}

const app = initializeApp(firebaseConfig)

export default app
