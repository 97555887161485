import { useState, useEffect, FC, useMemo } from 'react'
import { Close, FileUploadOutlined } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import { Button, List, ListItem, Typography } from '@mui/material'

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

// const focusedStyle = {
//   borderColor: '#2196f3',
// }

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type FileUploadProps = {
  setFiles: (files: File[]) => void
}

const FileUpload: FC<FileUploadProps> = ({ setFiles }) => {
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    noKeyboard: true,
    accept: { 'audio/mpeg': [] },
    maxFiles: 1,
  })

  const [uploaded, setUploaded] = useState<boolean>(false)

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setUploaded(true)
      setFiles(acceptedFiles)
    }
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  )

  const removeUploadedFile = () => {
    setUploaded(false)
    acceptedFiles.pop()
    setFiles([])
  }

  const acceptedFileItems = acceptedFiles.map((acceptedFile) => (
    <ListItem key={acceptedFile.name} className="file-item">
      <Button
        className="file-item-span"
        type="button"
        aria-label="Remove"
        onClick={removeUploadedFile}
      >
        <Close className="remove-uploaded-photo-button" />
      </Button>
      <span className="file-item-span">{acceptedFile.name}</span>
    </ListItem>
  ))

  return !uploaded ? (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <FileUploadOutlined />
      <Typography>Click/drag sermon audio file</Typography>
    </div>
  ) : (
    <div>
      <List>{acceptedFileItems}</List>
    </div>
  )
}

export default FileUpload
