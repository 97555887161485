import { FC, useState, SyntheticEvent, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Stack, Tab, Tabs } from '@mui/material'

const tabOptions = [
  {
    href: '/',
    label: 'Sermons',
    pages: ['/', '/create'],
  },
  {
    href: '/sermon-series',
    label: 'Sermon Series',
    pages: ['/sermon-series', '/sermon-series/create'],
  },
]

const NavTabs: FC = () => {
  const currentUrl = useLocation()

  const getTabIndex = (path: string) => {
    return tabOptions.findIndex((element) => element.pages.includes(path))
  }

  const [tabValue, setTabValue] = useState(getTabIndex(currentUrl.pathname))

  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue)
  }

  useEffect(() => {
    setTabValue(getTabIndex(currentUrl.pathname))
  }, [currentUrl])

  return (
    <Stack direction="row" alignItems="center">
      <Tabs value={tabValue} onChange={handleTabChange} textColor="inherit">
        {tabOptions.map(({ href, label }) => (
          <Tab key={label} component={Link} to={href} label={label} />
        ))}
      </Tabs>
    </Stack>
  )
}
export default NavTabs
